import React from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import * as propsValidation from "../../prop-types";
import { addTrackingToProductClick } from "../../../../../../js/feature/gtm-tracking";

class MobileNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavOpen: false,
      activeLevel1Index: null,
      activeLevel2Index: null,
      activeLevel3Index: null,
      activeLevel3GroupIndex: null
    };

    this.navItemsFromCurrentURL = {
      currentLevel1Index: null,
      currentLevel2Index: null,
      currentLevel3Index: null,
      currentLevel3GroupIndex: null
    };

    this.toggleNavigationDisplay = this.toggleNavigationDisplay.bind(this);
  }

  componentDidMount() {
    this.setNavItemsThatArePartOfCurrentURL();
  }

  componentDidUpdate(prevState) {
    if (this.state.isNavOpen !== prevState.isNavOpen) {
      this.handleWindowScroll();
    }
  }

  setNavItemsThatArePartOfCurrentURL() {
    const links = this.getLinksFromCurrentURL();
    this.props.navigation.map((item1, indexLevelOne) => {
      if (item1.nestedLinks !== null) {
        item1.nestedLinks.map((item2, indexLevelTwo) => {
          if (links.includes(item2.link.pathname)) {
            this.navItemsFromCurrentURL.currentLevel1Index = indexLevelOne;
            this.navItemsFromCurrentURL.currentLevel2Index = indexLevelTwo;
            if (item2.nestedLinks !== null) {
              item2.nestedLinks.map((item3, indexLevelThree) => {
                if (links.includes(item3.link.pathname)) {
                  this.navItemsFromCurrentURL.currentLevel3Index = indexLevelThree;
                  if (item2.groupedNestedLinks !== null) {
                    this.navItemsFromCurrentURL.currentLevel3GroupIndex = item2.groupedNestedLinks.findIndex((el) => {return el.some((item) => {return item.link.pathname === item3.link.pathname})});
                  }
                }
              });
            }
          }
        })
      }
    });
  }

  getLinksFromCurrentURL() {
    const urlParts = window.location.pathname.split('/').slice(1);
    const links = [];
    urlParts.map((urlPart, index) => {
      let linkUrl = '';
      for (let i = 0; i <= index; i++) {
        linkUrl += '/' + urlParts[i];
      }
      links.push(linkUrl);
    });
    return links;
  }

  handleLevelOnClick(e, index, level, isLast) {
    e.preventDefault();
    if (this.state[`activeLevel${level}Index`] !== index) {
      this.setState({ [`activeLevel${level}Index`]: index });
    }
    if (isLast) {
      addTrackingToProductClick(e, e.target.href, "_self", "navigation_menu");
    }
  }

  handleGroupOnClick(e, index) {
    e.preventDefault();
    if (this.state.activeLevel3GroupIndex !== index) {
      this.setState({ activeLevel3GroupIndex: index });
    }
  }

  toggleNavigationDisplay(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      isNavOpen: !prevState.isNavOpen,
      activeLevel1Index: null,
      activeLevel2Index: null,
      activeLevel3Index: null,
      activeLevel3GroupIndex: null
    }));
  }

  closeActiveSubNavigation(e, level) {
    e.preventDefault();
    this.setState({ [`activeLevel${level}Index`]: null, activeLevel3GroupIndex: null });
  }

  handleWindowScroll() {
    if (this.state.isNavOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  checkIfEachGroupHasTitle(groups) {
    if (groups !== null) {
      const groupsWithTitles = groups.filter((group, index) => this.props.dictionary[`LevelThreeTitle${index+1}`].length > 0);
      return groupsWithTitles.length === groups.length;
    }
  }

  render() {
    const { navigation } = this.props;
    const backButtonTitle = this.props.dictionary.BackButtonText;
    const openMenuLabel = this.props.dictionary.OpenMenuLabel;
    const closeMenuLabel = this.props.dictionary.CloseMenuLabel;
    const { isNavOpen, activeLevel1Index, activeLevel2Index, activeLevel3Index, activeLevel3GroupIndex } = this.state;
    const { currentLevel1Index, currentLevel2Index, currentLevel3Index, currentLevel3GroupIndex } = this.navItemsFromCurrentURL;
    return (
      <div className={`mobile-navigation`}>
        <button
          className={`mobile-navigation__burger ${isNavOpen ? 'open' : ''}`}
          aria-label={isNavOpen ? closeMenuLabel : openMenuLabel}
          onClick={(e) => this.toggleNavigationDisplay(e)}
        >
          <span className="mobile-navigation__burger__item" />
          <span className="mobile-navigation__burger__item" />
          <span className="mobile-navigation__burger__item" />
        </button>
        <CSSTransition in={isNavOpen} timeout={500} classNames="nav-animation">
          <div className={`navigation ${isNavOpen ? 'active' : 'inactive'}`}>
            <ul className="level1" role="menubar">
              {navigation.map((levelOne, levelOneIndex) => {
                const isFadedOfLevel1 = (currentLevel1Index !== levelOneIndex && currentLevel1Index !== null) || (activeLevel1Index !== levelOneIndex && activeLevel1Index !== null);
                return (
                  <li
                    key={levelOneIndex}
                    className={`level1__item ${activeLevel1Index === levelOneIndex ? 'active' : ''} ${currentLevel1Index === levelOneIndex ? 'current' : ''} ${isFadedOfLevel1 ? 'faded' : ''}`}
                    role="none"
                  >
                    <a
                      href={levelOne.link.href}
                      role="menuitem"
                      aria-expanded={activeLevel1Index === levelOneIndex}
                      aria-haspopup={levelOne.nestedLinks !== null}
                      onClick={(e) => this.handleLevelOnClick(e, levelOneIndex, 1)}
                      dangerouslySetInnerHTML={{ __html: levelOne.link.content }}
                    />
                    {levelOne.nestedLinks !== null &&
                    <CSSTransition in={activeLevel1Index === levelOneIndex} timeout={500} classNames="sub-nav-animation">
                      <div className="sub-navigation nav-level2">
                        <button
                          className="sub-navigation__button-back"
                          aria-label={backButtonTitle}
                          onClick={(e) => this.closeActiveSubNavigation(e, 1)}
                        ><span dangerouslySetInnerHTML={{ __html: backButtonTitle }} /></button>
                        <div className="sub-navigation__container">
                          <h3
                            className="sub-navigation__header"
                            dangerouslySetInnerHTML={{ __html: levelOne.link.content }}
                          />
                          <ul
                            className={`level2 ${activeLevel1Index === levelOneIndex ? 'active' : ''}`}
                            role="menu"
                            aria-label={levelOne.link.content}
                          >
                            {levelOne.nestedLinks.map((levelTwo, levelTwoIndex) => {
                              const isCurrentOfLevel2 = currentLevel1Index === levelOneIndex && currentLevel2Index === levelTwoIndex;
                              const isActiveOfLevel2 = activeLevel1Index === levelOneIndex && activeLevel2Index === levelTwoIndex;
                              const isFadedOfLevel2 = (activeLevel1Index === levelOneIndex && activeLevel2Index !== null && activeLevel2Index !== levelTwoIndex) || (currentLevel1Index === levelOneIndex && currentLevel2Index !== levelTwoIndex);
                              const hasChildren = levelTwo.nestedLinks !== null;
                              const hasGroupedChildren = hasChildren && levelTwo.groupedNestedLinks !== null && this.checkIfEachGroupHasTitle(levelTwo.groupedNestedLinks);
                              return (
                                <li
                                  key={levelTwoIndex}
                                  className={`level2__item ${isCurrentOfLevel2 ? 'current' : ''} ${isActiveOfLevel2 ? 'active' : ''} ${isFadedOfLevel2 ? 'faded' : ''} ${!hasChildren ? 'last': ''}`}
                                  role="none"
                                >
                                  {
                                    !hasChildren &&
                                    <a
                                      href={levelTwo.link.href}
                                      role="menuitem"
                                      onClick={(e) => this.handleLevelOnClick(e, levelTwoIndex, 2, true)}
                                      dangerouslySetInnerHTML={{ __html: levelTwo.link.content }}
                                    />
                                  }
                                  {
                                    hasChildren && !hasGroupedChildren &&
                                    <>
                                      <a
                                        href={levelTwo.link.href}
                                        role="menuitem"
                                        aria-expanded={isActiveOfLevel2}
                                        aria-haspopup={levelTwo.nestedLinks !== null}
                                        onClick={(e) => this.handleLevelOnClick(e, levelTwoIndex, 2)}
                                        dangerouslySetInnerHTML={{ __html: levelTwo.link.content }}
                                      />
                                      <CSSTransition in={isActiveOfLevel2} timeout={500} classNames="sub-nav-animation">
                                        <div className="sub-navigation nav-level3">
                                          <button
                                            className="sub-navigation__button-back"
                                            aria-label={backButtonTitle}
                                            onClick={(e) => this.closeActiveSubNavigation(e, 2)}
                                          ><span dangerouslySetInnerHTML={{ __html: levelOne.link.content }} /></button>
                                          <div className="sub-navigation__container">
                                            <h3 className="sub-navigation__header" dangerouslySetInnerHTML={{ __html: levelTwo.link.content }} />
                                            <ul
                                              className={`level3 ${isActiveOfLevel2 ? 'active' : ''} ${isCurrentOfLevel2 ? 'current' : ''}`}
                                              role="menu"
                                              aria-label={levelTwo.link.content}
                                            >
                                              {levelTwo.nestedLinks.map((levelThree, levelThreeIndex) => {
                                                const isCurrentOfLevel3 = isCurrentOfLevel2 && currentLevel3Index === levelThreeIndex;
                                                const isActiveOfLevel3 = isActiveOfLevel2 && activeLevel3Index === levelThreeIndex;
                                                const isFadedOfLevel3 = (isCurrentOfLevel2 && currentLevel3Index !== levelThreeIndex) || (activeLevel3Index !== null && activeLevel3Index !== levelThreeIndex && isActiveOfLevel2);
                                                return (
                                                  <li
                                                    key={levelThreeIndex}
                                                    className={`level3__item last ${isCurrentOfLevel3 ? 'current' : ''} ${isFadedOfLevel3 ? 'faded' : ''} ${isActiveOfLevel3 ? 'active' : ''}`}
                                                    role="none"
                                                  >
                                                    <a
                                                      href={levelThree.link.href}
                                                      role="menuitem"
                                                      onClick={(e) => this.handleLevelOnClick(e, levelThreeIndex, 3, true)}
                                                      dangerouslySetInnerHTML={{ __html: levelThree.link.content }}
                                                    />
                                                  </li>
                                                )
                                              })}
                                            </ul>
                                          </div>
                                        </div>
                                      </CSSTransition>
                                    </>
                                  }
                                  {
                                    hasGroupedChildren &&
                                    <>
                                      <a
                                        href={levelTwo.link.href}
                                        role="menuitem"
                                        aria-expanded={isActiveOfLevel2}
                                        aria-haspopup={levelTwo.nestedLinks !== null}
                                        onClick={(e) => this.handleLevelOnClick(e, levelTwoIndex, 2)}
                                        dangerouslySetInnerHTML={{ __html: levelTwo.link.content }}
                                      />
                                      <CSSTransition in={isActiveOfLevel2} timeout={500} classNames="sub-nav-animation">
                                        <div className="sub-navigation nav-level3">
                                          <button
                                            className="sub-navigation__button-back"
                                            aria-label={backButtonTitle}
                                            onClick={(e) => this.closeActiveSubNavigation(e, 2)}
                                          ><span dangerouslySetInnerHTML={{ __html: levelOne.link.content }} /></button>
                                          <div className="sub-navigation__container">
                                            <h3
                                              className="sub-navigation__header"
                                              dangerouslySetInnerHTML={{ __html: levelTwo.link.content }}
                                            />
                                            <ul
                                              className={`level3 ${isActiveOfLevel2 ? 'active' : ''} ${isCurrentOfLevel2 ? 'current' : ''}`}
                                              role="menu"
                                              aria-label={levelTwo.link.content}
                                            >
                                              {levelTwo.groupedNestedLinks.map((list, indexList) => {
                                                const isCurrentLevel3Group = isCurrentOfLevel2 && currentLevel3GroupIndex === indexList;
                                                const isActiveLevel3Group = isActiveOfLevel2 && activeLevel3GroupIndex === indexList;
                                                const isFadedOfLevel3Group = (isCurrentOfLevel2 && currentLevel3GroupIndex !== indexList) || (activeLevel3GroupIndex !== null && activeLevel3GroupIndex !== indexList && isActiveOfLevel2);
                                                return (
                                                  <li
                                                    key={indexList}
                                                    className={`level3__item ${isCurrentLevel3Group ? 'current' : ''} ${isFadedOfLevel3Group ? 'faded' : ''} ${isActiveLevel3Group ? 'active' : ''}`}
                                                    role="none"
                                                  >
                                                    <button
                                                      role="menuitem"
                                                      aria-expanded={isActiveLevel3Group}
                                                      aria-haspopup="true"
                                                      onClick={(e) => this.handleGroupOnClick(e, indexList)}
                                                      dangerouslySetInnerHTML={{ __html: this.props.dictionary[`LevelThreeTitle${indexList+1}`] }}
                                                    />
                                                    <CSSTransition in={isActiveLevel3Group} timeout={500} classNames="sub-nav-animation">
                                                      <div className="sub-navigation nav-level4">
                                                        <button
                                                          className="sub-navigation__button-back"
                                                          aria-label={backButtonTitle}
                                                          onClick={(e) => this.closeActiveSubNavigation(e, 3)}
                                                        ><span dangerouslySetInnerHTML={{ __html: levelTwo.link.content }} /></button>
                                                        <div className="sub-navigation__container">
                                                          <h3
                                                            className="sub-navigation__header"
                                                            dangerouslySetInnerHTML={{ __html: this.props.dictionary[`LevelThreeTitle${indexList+1}`] }}
                                                          />
                                                          <ul>
                                                            {list.map((item) => {
                                                              const itemIndex = levelTwo.nestedLinks.findIndex(el => el.link.href === item.link.href);
                                                              const isCurrentOfLevel3 = isCurrentLevel3Group && currentLevel3Index === itemIndex;
                                                              const isActiveOfLevel3 = isActiveOfLevel2 && activeLevel3Index === itemIndex;
                                                              const isFadedOfLevel3 = (isCurrentLevel3Group && currentLevel3Index !== itemIndex) || (activeLevel3Index !== null && activeLevel3Index !== itemIndex && isActiveOfLevel2);
                                                              return (
                                                                <li
                                                                  key={itemIndex}
                                                                  className={`level3__item last ${isCurrentOfLevel3 ? 'current' : ''} ${isActiveOfLevel3 ? 'active' : ''} ${isFadedOfLevel3 ? 'faded' : ''}`}
                                                                >
                                                                  <a
                                                                    href={item.link.href}
                                                                    onClick={(e) => this.handleLevelOnClick(e, itemIndex, 3, true)}
                                                                    role="menuitem"
                                                                    dangerouslySetInnerHTML={{ __html: item.link.content }}
                                                                  />
                                                                </li>
                                                              );
                                                            })}
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </CSSTransition>
                                                  </li>
                                                )
                                              })}
                                            </ul>
                                          </div>
                                        </div>
                                      </CSSTransition>
                                    </>
                                  }
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </CSSTransition>
                    }
                  </li>
                )})}
            </ul>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

MobileNavigation.propTypes = {
  navigation: propsValidation.navigation.isRequired
};

export default MobileNavigation;
