import React from 'react';
import * as propsValidation from "../prop-types";

const Link = (props) => {
  const { link } = props;
  return (
    <div className="link">
      <a
        href={link.href}
        className={link.class}
        target={link.target}
        title={link.title}>{link.content}</a>
    </div>
  );
};

Link.propTypes = {
  link: propsValidation.link.isRequired,
};

export default Link;
