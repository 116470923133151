require('core-js');require('regenerator-runtime/runtime');import $ from "jquery";
import cssLoader from "../lazy-loading-css-config";
cssLoader();
window.jQuery = $;
window.$ = $;
require("./project/ie-polyfills.js");
// Foundation
require("../../../wdh-foundation/dgs/dgs-api.js");
require("../../../wdh-foundation/dgs/dgs-events.js");
require("../../../wdh-foundation/dgs-sharing/dgs-sharing.js");
require("../../../wdh-foundation/dgs-utils/dgs-utils.js");
require("jquery-ui-dist/jquery-ui.min.js");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-a-data-hierarchy.js");

// Feature
require("./feature/cookies.js");
require("./feature/geo-ip-prompt.js");
require("./feature/intro-banner.js");
require("./feature/gtm-tracking.js");
require("../../../wdh-feature/wdh-locations-spot/src/js/utils/gtm-tracking.js");
require("./feature/search.js");
require("./feature/social-sharing");
require("./feature/text-image-spot.js");
require("./feature/hearing-test-intro.js");
require("./feature/form/salesforce-form.js");

// Project
require("./project/main.js");
require("../flex-layout/components/header/js/component-header");
require("../flex-layout/components/footer/js/component-footer");
require("./project/footer.js");
require("@demant/wdh-gdpr/lib/default.implementation.js");
require("@demant/legacy/custom-scroll");
    if (document.querySelector('.accordion-spot:not(.tabs)')) {
      import(/* webpackChunkName: "chunk-num-1", webpackPrefetch: true */'./feature/accordion-spot/index.js')
        .then(function (foo) { console.log('.accordion-spot:not(.tabs) LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .accordion-spot:not(.tabs)'); console.log(error); });
    }
    if (document.querySelector('.blog-categories')) {
      import(/* webpackChunkName: "chunk-num-2", webpackPrefetch: true */'../flex-layout/components/blog/blog-categories/js/component-blog-categories.js')
        .then(function (foo) { console.log('.blog-categories LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .blog-categories'); console.log(error); });
    }
    if (document.querySelector('.center-locator')) {
      import(/* webpackChunkName: "chunk-num-3", webpackPrefetch: true */'../flex-layout/components/center-locator/js/component-center-locator.js')
        .then(function (foo) { console.log('.center-locator LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .center-locator'); console.log(error); });
    }
    if (document.querySelector('.color-spot')) {
      import(/* webpackChunkName: "chunk-num-4", webpackPrefetch: true */'../flex-layout/components/color-selector-spot/js/component-color-selector-spot')
        .then(function (foo) { console.log('.color-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .color-spot'); console.log(error); });
    }
    if (document.querySelector('.compatibility-tool')) {
      import(/* webpackChunkName: "chunk-num-5", webpackPrefetch: true */'../wdh-compatibility-tool/index.js')
        .then(function (foo) { console.log('.compatibility-tool LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .compatibility-tool'); console.log(error); });
    }
    if (document.querySelector('.dictionary-post')) {
      import(/* webpackChunkName: "chunk-num-6", webpackPrefetch: true */'./feature/blog/dictionary-based-on-blog/dictionary.js')
        .then(function (foo) { console.log('.dictionary-post LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .dictionary-post'); console.log(error); });
    }
    if (document.querySelector('.embed-form-container')) {
      import(/* webpackChunkName: "chunk-num-7", webpackPrefetch: true */'./feature/form/embed-form.js')
        .then(function (foo) { console.log('.embed-form-container LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .embed-form-container'); console.log(error); });
    }
    if (document.querySelector('.form .scfForm')) {
      import(/* webpackChunkName: "chunk-num-8", webpackPrefetch: true */'./feature/form/standard-form/standard-form.js')
        .then(function (foo) { console.log('.form .scfForm LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .form .scfForm'); console.log(error); });
    }
    if (document.querySelector('.flex-spot.banner')) {
      import(/* webpackChunkName: "chunk-num-9", webpackPrefetch: true */'../flex-layout/components/flex-spot/banner/js/component-flex-spot-banner.js')
        .then(function (foo) { console.log('.flex-spot.banner LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .flex-spot.banner'); console.log(error); });
    }
    if (document.querySelector('.hearing-test-invitation')) {
      import(/* webpackChunkName: "chunk-num-10", webpackPrefetch: true */'../flex-layout/components/hearing-test-invitation/js/component-hearing-test-invitation')
        .then(function (foo) { console.log('.hearing-test-invitation LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-test-invitation'); console.log(error); });
    }
    if (document.querySelector('.hearing-test-spot:not(.quiz)')) {
      import(/* webpackChunkName: "chunk-num-11", webpackPrefetch: true */'./feature/hearing-test')
        .then(function (foo) { console.log('.hearing-test-spot:not(.quiz) LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-test-spot:not(.quiz)'); console.log(error); });
    }
    if (document.querySelector('.hearing-test-spot.quiz')) {
      import(/* webpackChunkName: "chunk-num-12", webpackPrefetch: true */'./feature/quiz')
        .then(function (foo) { console.log('.hearing-test-spot.quiz LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-test-spot.quiz'); console.log(error); });
    }
    if (document.querySelector('.hearing-loss-simulator-spot.component')) {
      import(/* webpackChunkName: "chunk-num-13", webpackPrefetch: true */'../wdh-hearing-loss-simulator/index.js')
        .then(function (foo) { console.log('.hearing-loss-simulator-spot.component LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-loss-simulator-spot.component'); console.log(error); });
    }
    if (document.querySelector('iframe')) {
      import(/* webpackChunkName: "chunk-num-14", webpackPrefetch: true */'./feature/iframe.js')
        .then(function (foo) { console.log('iframe LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for iframe'); console.log(error); });
    }
    if (document.querySelector('.image-spot.component:not(.accordion-img-as-tab):not(.buttons-container):not(.as-carousel):not(.side-accordion)')) {
      import(/* webpackChunkName: "chunk-num-15", webpackPrefetch: true */'../flex-layout/components/image-spot/js/components/default-image-spot.jsx')
        .then(function (foo) { console.log('.image-spot.component:not(.accordion-img-as-tab):not(.buttons-container):not(.as-carousel):not(.side-accordion) LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .image-spot.component:not(.accordion-img-as-tab):not(.buttons-container):not(.as-carousel):not(.side-accordion)'); console.log(error); });
    }
    if (document.querySelector('.image-spot.component.accordion-img-as-tab')) {
      import(/* webpackChunkName: "chunk-num-16", webpackPrefetch: true */'../flex-layout/components/image-spot/js/components/accordion-image-spot.jsx')
        .then(function (foo) { console.log('.image-spot.component.accordion-img-as-tab LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .image-spot.component.accordion-img-as-tab'); console.log(error); });
    }
    if (document.querySelector('.image-spot.component.as-carousel')) {
      import(/* webpackChunkName: "chunk-num-17", webpackPrefetch: true */'../flex-layout/components/image-spot/js/components/carousel-image-spot/index.jsx')
        .then(function (foo) { console.log('.image-spot.component.as-carousel LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .image-spot.component.as-carousel'); console.log(error); });
    }
    if (document.querySelector('.image-spot.component.buttons-container')) {
      import(/* webpackChunkName: "chunk-num-18", webpackPrefetch: true */'../flex-layout/components/image-spot/js/components/buttons-container-image-spot.jsx')
        .then(function (foo) { console.log('.image-spot.component.buttons-container LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .image-spot.component.buttons-container'); console.log(error); });
    }
    if (document.querySelector('.image-spot.component.side-accordion')) {
      import(/* webpackChunkName: "chunk-num-19", webpackPrefetch: true */'../flex-layout/components/image-spot/js/components/side-accordion-image-spot.jsx')
        .then(function (foo) { console.log('.image-spot.component.side-accordion LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .image-spot.component.side-accordion'); console.log(error); });
    }
    if (document.querySelector('.location-selector')) {
      import(/* webpackChunkName: "chunk-num-20", webpackPrefetch: true */'./project/location-selector.js')
        .then(function (foo) { console.log('.location-selector LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .location-selector'); console.log(error); });
    }
    if (document.querySelector('.locations-spot')) {
      import(/* webpackChunkName: "chunk-num-21", webpackPrefetch: true */'./feature/location-spot/location-spot')
        .then(function (foo) { console.log('.locations-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .locations-spot'); console.log(error); });
    }
    if (document.querySelector('.news-list-spot')) {
      import(/* webpackChunkName: "chunk-num-22", webpackPrefetch: true */'../flex-layout/components/news-list-spot/js/component-news-list-spot')
        .then(function (foo) { console.log('.news-list-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .news-list-spot'); console.log(error); });
    }
    if (document.querySelector('.news-spot-item')) {
      import(/* webpackChunkName: "chunk-num-23", webpackPrefetch: true */'../flex-layout/components/news-spot/js/component-news-spot')
        .then(function (foo) { console.log('.news-spot-item LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .news-spot-item'); console.log(error); });
    }
    if (document.querySelector('.news-navigation')) {
      import(/* webpackChunkName: "chunk-num-24", webpackPrefetch: true */'../flex-layout/components/news-navigation/js/component-news-navigation')
        .then(function (foo) { console.log('.news-navigation LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .news-navigation'); console.log(error); });
    }
    if (document.querySelector('.phone-compatibility:not(.custom), .phone-detection')) {
      import(/* webpackChunkName: "chunk-num-25", webpackPrefetch: true */'../../oticon/custom-components/flex-spot/compatibility-phones/index')
        .then(function (foo) { console.log('.phone-compatibility:not(.custom), .phone-detection LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .phone-compatibility:not(.custom), .phone-detection'); console.log(error); });
    }
    if (document.querySelector('.resource-center')) {
      import(/* webpackChunkName: "chunk-num-26", webpackPrefetch: true */'./feature/wdh-resource-center')
        .then(function (foo) { console.log('.resource-center LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .resource-center'); console.log(error); });
    }
    if (document.querySelector('.side-navigation.link-list')) {
      import(/* webpackChunkName: "chunk-num-27", webpackPrefetch: true */'../flex-layout/components/side-navigation/js/side-navigation.js')
        .then(function (foo) { console.log('.side-navigation.link-list LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .side-navigation.link-list'); console.log(error); });
    }
    if (document.querySelector('.two-sections-layout.flex-spot')) {
      import(/* webpackChunkName: "chunk-num-28", webpackPrefetch: true */'../flex-layout/components/flex-spot/two-sections-layout/js/component-flex-spot-two-sections-layout')
        .then(function (foo) { console.log('.two-sections-layout.flex-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .two-sections-layout.flex-spot'); console.log(error); });
    }
    if (document.querySelector('.ui-elements.data-hierarchy-spot')) {
      import(/* webpackChunkName: "chunk-num-29", webpackPrefetch: true */'../flex-layout/components/ui-elements/js/ui-elements.js')
        .then(function (foo) { console.log('.ui-elements.data-hierarchy-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .ui-elements.data-hierarchy-spot'); console.log(error); });
    }
    if (document.querySelector('.video-spot.component')) {
      import(/* webpackChunkName: "chunk-num-30", webpackPrefetch: true */'./feature/video-spot')
        .then(function (foo) { console.log('.video-spot.component LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .video-spot.component'); console.log(error); });
    }
    if (document.querySelector('.accordion-spot')) {
      var link = document.querySelector('link[data-component^="accordion-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.blog-categories')) {
      var link = document.querySelector('link[data-component^="blog-categories"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.center-locator')) {
      var link = document.querySelector('link[data-component^="center-locator"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.color-selector-spot')) {
      var link = document.querySelector('link[data-component^="color-selector-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.compatibility-tool')) {
      var link = document.querySelector('link[data-component^="compatibility-tool"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.dictionary')) {
      var link = document.querySelector('link[data-component^="dictionary"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.page-dir-rtl')) {
      var link = document.querySelector('link[data-component^="page-dir-rtl"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.embed-form')) {
      var link = document.querySelector('link[data-component^="embed-form"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.flex-spot-banner')) {
      var link = document.querySelector('link[data-component^="flex-spot-banner"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-loss-simulator-spot')) {
      var link = document.querySelector('link[data-component^="hearing-loss-simulator-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-test-invitation')) {
      var link = document.querySelector('link[data-component^="hearing-test-invitation"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-test-spot')) {
      var link = document.querySelector('link[data-component^="hearing-test-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.image-spot-accordion')) {
      var link = document.querySelector('link[data-component^="image-spot-accordion"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.image-spot-as-carousel')) {
      var link = document.querySelector('link[data-component^="image-spot-as-carousel"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.image-spot-buttons-container')) {
      var link = document.querySelector('link[data-component^="image-spot-buttons-container"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.image-spot-side-accordion')) {
      var link = document.querySelector('link[data-component^="image-spot-side-accordion"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.location-selector')) {
      var link = document.querySelector('link[data-component^="location-selector"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.locations-spot')) {
      var link = document.querySelector('link[data-component^="locations-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.news-list-spot')) {
      var link = document.querySelector('link[data-component^="news-list-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.news-spot-item')) {
      var link = document.querySelector('link[data-component^="news-spot-item"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.news-navigation')) {
      var link = document.querySelector('link[data-component^="news-navigation"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.phone-compatibility')) {
      var link = document.querySelector('link[data-component^="phone-compatibility"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.quiz')) {
      var link = document.querySelector('link[data-component^="quiz"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.resource-center')) {
      var link = document.querySelector('link[data-component^="resource-center"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.standard-form')) {
      var link = document.querySelector('link[data-component^="standard-form"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.search-field-spot')) {
      var link = document.querySelector('link[data-component^="search-field-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.search-result-spot')) {
      var link = document.querySelector('link[data-component^="search-result-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.side-navigation')) {
      var link = document.querySelector('link[data-component^="side-navigation"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.two-column-spot')) {
      var link = document.querySelector('link[data-component^="two-column-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.two-sections-layout')) {
      var link = document.querySelector('link[data-component^="two-sections-layout"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.ui-elements')) {
      var link = document.querySelector('link[data-component^="ui-elements"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.video-spot')) {
      var link = document.querySelector('link[data-component^="video-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }