import PropTypes from 'prop-types';
export var isTablet = PropTypes.bool;
export var isDesktop = PropTypes.bool;
export var isScrolled = PropTypes.bool;
export var pageScrollOffset = PropTypes.number;
export var dictionary = PropTypes.shape({
  Logo: PropTypes.shape({
    textUnderLogo: PropTypes.string.isRequired
  }),
  Navigation: PropTypes.shape({
    BackButtonText: PropTypes.string.isRequired
  }),
  Search: PropTypes.shape({
    Placeholder: PropTypes.string.isRequired
  })
});
export var search = PropTypes.oneOfType([PropTypes.shape({
  searchUrl: PropTypes.string.isRequired
}).isRequired, PropTypes.oneOf([null]).isRequired]);
export var languageSelector = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
  class: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}).isRequired), PropTypes.oneOf([null]).isRequired]);
export var logo = PropTypes.oneOfType([PropTypes.shape({
  image: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }).isRequired,
  link: PropTypes.shape({
    class: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
}).isRequired, PropTypes.oneOf([null]).isRequired]);
export var link = PropTypes.oneOfType([PropTypes.shape({
  class: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}).isRequired, PropTypes.oneOf([null]).isRequired]);
export var breadcrumb = PropTypes.oneOfType([PropTypes.shape({
  blueText: PropTypes.bool.isRequired,
  linkList: PropTypes.arrayOf(PropTypes.shape({
    class: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired)
}).isRequired, PropTypes.oneOf([null]).isRequired]);
export var navigation = PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.oneOf([null]).isRequired]);