import React from "react";
import * as propsValidation from "../prop-types";
import PropTypes from "prop-types";

const position = document.body.classList.contains("page-dir-rtl")
  ? "right"
  : "left";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displaySearch: false,
    };
  }

  componentDidUpdate() {
    const resultPage = this.props.resultPage;
    $(".search__container input")
      .autocomplete({
        source(request, response) {
          $.ajax({
            url: `/webservices/search.svc/suggest/${$(
              ".search__container input"
            ).val()}/en-ca`,
            type: "GET",
            dataType: "json",
            success(data) {
              response(data.Suggests);
            },
            error(xhr, textStatus, errorThrown) {
              console.log("error search-field-spot ", url);
            },
          });
        },
        select(a, b) {
          const searchString = `?q=${b.item.value}`;
          window.location = resultPage + searchString;
        },
        open() {
          $("ul.ui-menu").width($(".search__container form").innerWidth());
          $("ul.ui-menu").width($(".search__container form").innerWidth());
        },
        position: { my: `${position} top`, at: `${position} bottom` },
      })
      .autocomplete("widget")
      .appendTo(".search__container")
      .addClass("ui-autocomplete-search");
  }

  handleOpenSearch(e) {
    e.preventDefault();
    this.setState({ displaySearch: true });
    if (!this.props.isTablet) {
      this.props.handleSearchDisplay(true);
    }
  }

  handleCloseSearch(e) {
    e.preventDefault();
    this.setState({ displaySearch: false });
    if (!this.props.isTablet) {
      this.props.handleSearchDisplay(false);
    }
  }

  render() {
    const { placeholder, resultPage } = this.props;
    const { displaySearch } = this.state;
    return (
      <div className={`search ${displaySearch ? "open" : ""}`}>
        {!displaySearch ? (
          <button
            className="search__btn-open"
            aria-label="Search"
            onClick={(e) => this.handleOpenSearch(e)}
          >
            Search
          </button>
        ) : (
          <div className="search__container">
            <form action={resultPage}>
              <input
                type="text"
                className="ui-autocomplete-input"
                placeholder={placeholder}
                aria-label={placeholder}
                autoComplete="off"
                name="q"
                autoFocus
              />
              <button type="submit">Submit</button>
            </form>
            <button
              className="search__btn-close"
              onClick={(e) => this.handleCloseSearch(e)}
            >
              Close
            </button>
          </div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  resultPage: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTablet: propsValidation.isTablet.isRequired,
  handleSearchDisplay: PropTypes.func,
};

export default Search;
