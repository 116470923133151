(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    removeBorderFromFirstLinkInLine();
    DGS.Resizer.getInstance().register(function () {
      removeBorderFromFirstLinkInLine();
    });
    function removeBorderFromFirstLinkInLine() {
      var links = getFooterLinks();
      var maxWidth = getFooterLinkListWidth();
      var currentLineWidth = 0;
      links.each(function () {
        var currentLink = $(this);
        var currentLinkWidth = currentLink.outerWidth();
        currentLineWidth += currentLinkWidth;
        currentLink.removeClass('first-in-line');
        if (currentLineWidth > maxWidth) {
          currentLink.addClass('first-in-line');
          currentLineWidth = currentLinkWidth;
        }
      });
    }
    function getFooterLinks() {
      var links = $('#footer .section-two .rich-text ul li');
      return links;
    }
    function getFooterLinkListWidth() {
      var linklistwidth = $('#footer .section-two .rich-text ul').width();
      return linklistwidth;
    }
  });
})(jQuery, window.DGS);