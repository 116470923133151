import { trackHearingTest } from "./gtm-tracking";
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var buttons = document.querySelectorAll(".hearing-test-intro-button a");
    buttons.forEach(function (button) {
      button.addEventListener("click", function (e) {
        e.preventDefault();
        trackHearingTest(0, "info_page", 0);
        window.open(button.href, "_self");
      });
    });
  });
})(jQuery, window.DGS);