import React from "react";
import PropTypes from "prop-types";
import * as propsValidation from "../prop-types";
import Logo from "../components/logo.jsx";
import MobileNavigation from "../components/navigation/mobile-navigation.jsx";
import Link from "../components/link.jsx";
import LanguageSelector from "../components/language-selector.jsx";
import Search from "../components/search.jsx";

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollPosition: 0,
      searchOpen: false
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearchDisplay = this.handleSearchDisplay.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    document.body.classList.remove("header-slide-up");
  }

  handleScroll() {
    const headerHeight = this.props.component.clientHeight;
    const holdUp = 200;
    if (
      Math.abs(this.state.scrollPosition - this.props.pageScrollOffset) <=
        holdUp &&
      !this.props.isTablet
    ) {
      return;
    }
    if (
      this.props.pageScrollOffset > this.state.scrollPosition &&
      this.props.pageScrollOffset > headerHeight
    ) {
      this.props.component.classList.add("slide-up");
      document.body.classList.add("header-slide-up");
    } else {
      if (
        this.props.pageScrollOffset + window.innerHeight <
        document.body.scrollHeight
      ) {
        this.props.component.classList.remove("slide-up");
        document.body.classList.remove("header-slide-up");
      }
    }
    this.setState({ scrollPosition: this.props.pageScrollOffset });
  }

  handleSearchDisplay(isOpen) {
    this.setState({ searchOpen: isOpen });
  }

  render() {
    const {
      logo,
      languageSelector,
      link,
      navigation,
      search
    } = this.props.data;
    const { dictionary, isTablet } = this.props;
    const { searchOpen } = this.state;

    return (
      <React.Fragment>
        <div className="header__mobile__container">
          <div
            className={`header__mobile__sub-container ${
              searchOpen ? "search-open" : ""
            }`}
          >
            <Logo logo={logo} title={dictionary.Logo.textUnderLogo} />
            {languageSelector !== null && (
              <LanguageSelector languageSelector={languageSelector} />
            )}
            <Link link={link} />
            <Search
              resultPage={search.searchUrl}
              placeholder={dictionary.Search.Placeholder}
              isTablet={isTablet}
              handleSearchDisplay={this.handleSearchDisplay}
            />
            <MobileNavigation
              navigation={navigation}
              dictionary={dictionary.Navigation}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

//dodaj component
MobileHeader.propTypes = {
  pageScrollOffset: propsValidation.pageScrollOffset.isRequired,
  isTablet: propsValidation.isTablet.isRequired,
  data: PropTypes.shape({
    breadcrumb: propsValidation.breadcrumb,
    languageSelector: propsValidation.languageSelector,
    link: propsValidation.link,
    logo: propsValidation.logo,
    navigation: propsValidation.navigation,
    search: propsValidation.search
  }).isRequired,
  dictionary: propsValidation.dictionary.isRequired
};

export default MobileHeader;
