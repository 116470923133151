import React from 'react';
import * as propsValidation from "../prop-types";

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);

    this.currentLanguage = document.documentElement.lang;
    this.items = this.props.languageSelector;

    this.state = {
      currentIndex: null,
    };
  }

  componentDidMount() {
    this.getCurrentIndex();
  }

  getCurrentIndex() {
    this.items.map((item, index) => {
      if (item.href.toLowerCase().includes(this.currentLanguage)) {
        this.setState({ currentIndex: index })
      }
    });
  }

  render() {
    const { currentIndex } = this.state;
    return (
      <div className="language-selector-spot">
        <ul>
          {this.items.map((item, index) => (
            <li key={index}>
              {currentIndex === index ?
                (<p className="current">{item.content}</p>) :
                (<a href={item.href}>{item.content}</a>)
              }
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

LanguageSelector.propTypes = {
  languageSelector: propsValidation.languageSelector.isRequired,
};

export default LanguageSelector;
