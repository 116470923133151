import React from 'react';
import PropTypes from 'prop-types';
import * as propsValidation from '../prop-types';
import Breadcrumb from '../components/breadcrumb.jsx';
import DesktopNavigation from '../components/navigation/desktop-navigation.jsx';
import LanguageSelector from '../components/language-selector.jsx';
import Link from '../components/link.jsx';
import Logo from '../components/logo.jsx';
import Search from '../components/search.jsx';

const DesktopHeader = (props) => {
  const {
    breadcrumb,
    languageSelector,
    link,
    logo,
    navigation,
    search
  } = props.data;

  const { dictionary, isTablet, isScrolled } = props;

  return (
    <React.Fragment>
      <div className="header__section-top">
        <div className="header__section-top__container">
          <Logo logo={logo} title={dictionary.Logo.textUnderLogo} />
          <DesktopNavigation navigation={navigation} dictionary={dictionary.Navigation} />
          {languageSelector !== null && <LanguageSelector languageSelector={languageSelector} />}
          <Link link={link} />
          {languageSelector !== null && <LanguageSelector languageSelector={languageSelector} />}
          <Search resultPage={search.searchUrl} placeholder={dictionary.Search.Placeholder} isTablet={isTablet} />
        </div>
      </div>
      {
        breadcrumb !== null &&
        <div className={`header__section-bottom ${isScrolled ? 'scrolled' : ''} ${breadcrumb.blueText ? "dark" : ""}`}>
          <div className="header__section-bottom__container">
            <Breadcrumb breadcrumb={breadcrumb} />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

DesktopHeader.propTypes = {
  isScrolled: propsValidation.isScrolled.isRequired,
  isTablet: propsValidation.isTablet.isRequired,
  data: PropTypes.shape({
    breadcrumb: propsValidation.breadcrumb,
    languageSelector: propsValidation.languageSelector,
    link: propsValidation.link,
    logo: propsValidation.logo,
    navigation: propsValidation.navigation,
    search: propsValidation.search
  }).isRequired,
  dictionary: propsValidation.dictionary.isRequired,
};

export default DesktopHeader;
