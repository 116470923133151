function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { resolveImageSrc } from "@demant/wdh";
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var DESKTOP_BP = window.matchMedia("(min-width: 992px)");
    updateTextImgSpot();
    function updateTextImgSpot() {
      imgAsBackgroundForImgWidth50();
      imgAsBackgroundForImgWidth35();
      setFirstHeaderAboveTheContent();
    }
    function imgAsBackgroundForImgWidth35() {
      var textImageSpot = $('.text-image-spot.img-35-text-65');
      textImageSpot.each(function () {
        var self = $(this);
        imgAsBackground(self, 35, 'vw');
      });
    }
    function imgAsBackgroundForImgWidth50() {
      var textImageSpot = $('.text-image-spot').not('.img-35-text-65, .fit-content-into-page-container:not(.embed-form-container), .img-not-as-bg');
      textImageSpot.each(function () {
        var self = $(this);
        imgAsBackground(self, 50, 'vw');
      });
    }
    function imgAsBackground(textImgSpot, imgContainerWidth, widthUnits) {
      var img = textImgSpot.find('img')[0];
      var imgUrl = resolveImageSrc(img);
      var ratio = (img.height / img.width).toFixed(2);
      var imgContainer = textImgSpot.find('.text-image-spot-image-container');
      var imgContainerHeight = imgContainerWidth * ratio + widthUnits;
      var imgContainerStyle = {
        'background-image': 'url("' + imgUrl + '")',
        'height': imgContainerHeight
      };
      addStyleIfDesktop(imgContainer, imgContainerStyle);
      DGS.Resizer.getInstance().register(function () {
        addStyleIfDesktop(imgContainer, imgContainerStyle);
      });
    }
    function setFirstHeaderAboveTheContent() {
      var headers = ['H1', 'H2', 'H3', 'H4', 'H5'];
      var textImgSpot = document.querySelectorAll('.text-image-spot.headline-above-the-content');
      textImgSpot.forEach(function (el) {
        var textElements = el.querySelector('.text-image-spot-text').children;
        var container = el.querySelector('.component-content');
        var header = _toConsumableArray(textElements).filter(function (item) {
          return headers.includes(item.nodeName);
        })[0];
        var headline = header.cloneNode(true);
        header.classList.add('text-image-spot-header');
        headline.classList.add('text-image-spot-headline');
        container.insertBefore(headline, container.firstChild);
      });
    }
    function addStyleIfDesktop(element, style) {
      if (DESKTOP_BP.matches) {
        element.css(style);
      } else {
        element.removeAttr('style');
      }
    }
  });
})(jQuery, window.DGS);