import { trackFormSubmit } from "../gtm-tracking";
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var createForm = function createForm() {
      var formContainer = document.querySelector("#wrapper #content .salesforce-form-container.component .component-content");
      var formData = document.querySelector("#wrapper #content .salesforce-form-container.component .component-content [data-form-action]");
      if (!(formData !== null && formData !== void 0 && formData.getAttribute("data-form-action"))) {
        console.error("Error! Not able to create the form. Please check if the data-form-action attribute is added correctly.");
      }
      var formContent = formContainer.innerHTML;
      var form = document.createElement("form");
      form.classList.add("salesforce-form");
      form.method = (formData === null || formData === void 0 ? void 0 : formData.getAttribute("data-form-method")) || "POST";
      form.action = formData === null || formData === void 0 ? void 0 : formData.getAttribute("data-form-action");
      form.innerHTML = formContent;
      formContainer.innerHTML = "";
      formContainer.append(form);
    };
    var handleLayout = function handleLayout() {
      var form = document.querySelector("#wrapper #content .salesforce-form-container.component form");
      var formFields = form.querySelectorAll('input:not([type="hidden"]):not([type="submit"]):not([type="checkbox"]), textarea, select');
      formFields.forEach(function (field) {
        var label = $(field).prevAll("label")[0];
        var errorData = $(field).nextAll("input:not([type='checkbox'])[errormsg]")[0];
        $(errorData).addClass("error-data");
        var errorMsg = $("<div class=\"error-msg\">".concat($(errorData).attr("errormsg"), "</div>"));
        $(field).add($(label)).add($(errorData)).add($(errorMsg)).wrapAll('<div class="field-wrapper"></div>');
        if ($(field).prop("tagName") === "SELECT") {
          $(field).wrap('<div class="select-wrapper"></div>');
        }
        if ($(field).hasClass("half-width")) {
          $(field).parent().addClass("half-width");
        }
      });
      form.querySelectorAll('input[type="checkbox"]').forEach(function (checkbox) {
        var label = $(checkbox).nextAll("label")[0];
        var errorText = $(checkbox).attr("errormsg");
        var errorMsg = $("<div class=\"error-msg\">".concat(errorText, "</div>"));
        $(checkbox).add($(label)).add($(errorMsg)).wrapAll('<div class="checkbox-wrapper"></div>');
      });
    };
    var handleCustomValidation = function handleCustomValidation() {
      var form = document.querySelector("#wrapper #content .salesforce-form-container.component form");
      var formFields = form.querySelectorAll('input:not([type="hidden"]):not([type="submit"]), textarea, select');
      var submitBtn = form.querySelector("input[type='submit']");
      var checkIfRequired = function checkIfRequired(field) {
        var _field$parentElement;
        return ((_field$parentElement = field.parentElement) === null || _field$parentElement === void 0 || (_field$parentElement = _field$parentElement.querySelector(".error-data")) === null || _field$parentElement === void 0 ? void 0 : _field$parentElement.getAttribute("req")) === "true" || (field === null || field === void 0 ? void 0 : field.getAttribute("required")) === "true";
      };
      var validateEmail = function validateEmail(field) {
        if (field.getAttribute("name") === "email") {
          var emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          var emailIsInvalid = !emailRegex.test(field.value) && field.value.length > 0;
          var emails = form.querySelectorAll('input[name="email"]');
          if (emails.length > 1) {
            var emailsDoNotMatch = emails[0].value.trim() !== emails[1].value.trim();
            return emailsDoNotMatch || emailIsInvalid;
          }
          return emailIsInvalid;
        }
      };
      var validateCustomRegex = function validateCustomRegex(field) {
        var _field$parentElement2;
        var hasCustomRegex = (_field$parentElement2 = field.parentElement) === null || _field$parentElement2 === void 0 || (_field$parentElement2 = _field$parentElement2.querySelector(".error-data")) === null || _field$parentElement2 === void 0 || (_field$parentElement2 = _field$parentElement2.getAttribute("customregex")) === null || _field$parentElement2 === void 0 ? void 0 : _field$parentElement2.slice(1, -1);
        if (hasCustomRegex && hasCustomRegex.length > 0) {
          var formatedRegex = new RegExp(hasCustomRegex);
          return !formatedRegex.test(field.value);
        }
      };
      var validate = function validate(field) {
        var conditionsForRequired = {
          textinput: field.getAttribute("type") === "text" && checkIfRequired(field) && field.value.length < 1,
          select: checkIfRequired(field) && field.value < 0,
          checkbox: field.getAttribute("type") === "checkbox" && !field.checked && checkIfRequired(field),
          textarea: field.nodeName.toLowerCase() === "textarea" && checkIfRequired(field) && field.value.length < 1
        };
        var conditionsForInvalid = {
          email: validateEmail(field),
          customRegex: validateCustomRegex(field)
        };
        var isRequiredAndEmpty = Object.values(conditionsForRequired).includes(true);
        var isInvalid = Object.values(conditionsForInvalid).includes(true);
        var fieldWrapper = field.parentElement;
        var errorData = fieldWrapper.querySelector(".error-data");
        var addError = function addError(validationText) {
          fieldWrapper.classList.add("error");
          if (field.getAttribute("type") !== "checkbox") {
            fieldWrapper.querySelector(".error-msg").innerText = validationText;
          }
        };
        if (isRequiredAndEmpty) {
          var requiredErrorMessage = errorData === null || errorData === void 0 ? void 0 : errorData.getAttribute("requrederrormsg");
          addError(requiredErrorMessage);
        } else if (isInvalid) {
          var invalidErrorMessage = errorData === null || errorData === void 0 ? void 0 : errorData.getAttribute("errormsg");
          addError(invalidErrorMessage);
        } else {
          fieldWrapper.classList.remove("error");
        }
      };
      var scrollToFirstError = function scrollToFirstError() {
        var error = form.querySelector(".error");
        error.scrollIntoView();
        window.scrollBy(0, -60);
      };
      var addTracking = function addTracking() {
        trackFormSubmit(window.location.pathname);
      };
      var handleOnSubmit = function handleOnSubmit() {
        formFields.forEach(function (field) {
          return validate(field);
        });
        var isFormValid = form.querySelectorAll(".error").length === 0;
        if (isFormValid) {
          addTracking();
          submitBtn.click();
        } else {
          scrollToFirstError();
        }
      };
      var handleSubmitButton = function handleSubmitButton() {
        var newElement = document.createElement("button");
        newElement.innerText = submitBtn.value;
        newElement.setAttribute("onclick", null);
        newElement.setAttribute("type", "button");
        newElement.classList.add("fake-button");
        newElement.id = "fakeBtnSubmit";
        submitBtn.parentElement.appendChild(newElement);
        submitBtn.style.display = "none";
        form.querySelector(".fake-button").addEventListener("click", handleOnSubmit, false);
      };
      var handleInputsOnBlur = function handleInputsOnBlur() {
        formFields.forEach(function (field) {
          field.addEventListener("blur", function () {
            return validate(field);
          });
        });
      };
      handleInputsOnBlur();
      handleSubmitButton();
    };
    var init = function init() {
      createForm();
      handleLayout();
      handleCustomValidation();
    };
    init();
  });
})(jQuery);