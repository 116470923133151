import React from 'react';
import PropTypes from 'prop-types';
import * as propsValidation from './prop-types';
import ReactHOC from "../../../common/react-hoc";
import DesktopHeader from "./views/desktop.jsx";
import MobileHeader from "./views/mobile.jsx";

class HeaderApp extends React.Component {
  constructor(props) {
    super(props);

    this.defaultDictionary = {
      Logo: {
        textUnderLogo: 'Home',
      },
      Navigation: {
        OpenMenuLabel: 'Open the menu',
        CloseMenuLabel: 'Close the menu',
        BackButtonText: 'Back',
        LevelThreeTitle1: 'Test 1',
        LevelThreeTitle2: 'Test 2',
        LevelThreeTitle3: 'Test 3',
        LevelThreeTitle4: 'Test 4'
      },
      Search: {
        Placeholder: 'What are you looking for?',
      },
    };

    this.state = {
      dictionary: this.defaultDictionary
    };

    this.getDictionary = this.getDictionary.bind(this);
  }

  componentDidMount() {
    this.getDictionary();
    this.props.component.classList.add('loaded');
  }

  getDictionary() {
    const theLang = document.documentElement.lang;
    DGS.Dictionary.getInstance().getTexts(this.defaultDictionary, () => {
      this.setState({ dictionary: this.defaultDictionary });
    }, theLang);
  }

  render() {
    const {
      settings,
      isTablet,
      isScrolled,
      pageScrollOffset,
      component
    } = this.props;

    const { dictionary } = this.state;

    return (
      <div className={`flex-header-box ${isTablet ? 'header__desktop' : 'header__mobile'}`}>
        {
          isTablet ?
          (
            <DesktopHeader
              dictionary={dictionary}
              data={settings}
              isTablet={isTablet}
              isScrolled={isScrolled}
            />
          ) :
          (
            <MobileHeader
              dictionary={dictionary}
              data={settings}
              isTablet={isTablet}
              pageScrollOffset={pageScrollOffset}
              component={component}
            />
          )
        }
      </div>
    )
  }
}

//Dodaj component do proptypes
HeaderApp.propTypes = {
  isScrolled: propsValidation.isScrolled.isRequired,
  isTablet: propsValidation.isTablet.isRequired,
  isDesktop: propsValidation.isDesktop.isRequired,
  pageScrollOffset: propsValidation.pageScrollOffset.isRequired,
  settings: PropTypes.shape({
    breadcrumb: propsValidation.breadcrumb,
    languageSelector: propsValidation.languageSelector,
    link: propsValidation.link,
    logo: propsValidation.logo,
    navigation: propsValidation.navigation,
    search: propsValidation.search
  }).isRequired,
};

export default ReactHOC(HeaderApp);
