import React from 'react';
import PropTypes from 'prop-types';
import * as propsValidation from "../prop-types";

const Logo = (props) => {
  const {
    logo,
    title
  } = props;

  return (
    <div className="header-logo">
      <a href={logo.link.href} title={title} className="shapelink">
        <div className="brand">
          <div className="shapegradient">
            <div>{title}</div>
          </div>
          <div className="philips">
            <img src={logo.image.src} alt={logo.image.alt}/>
          </div>
          <div className="shapetop" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMidYMid meet" x="0" y="0" width="98" height="45" viewBox="0, 0, 196, 90">
              <path d="M154.001,90.001 C176.012,90.022 195.966,82.011 196.001,60 L196.001,0.001 L-0.001,0.001 L-0.001,90.001 L154.001,90.001 z" fill="#fff" id="Shape_top" />
            </svg>
          </div>
        </div>
      </a>
    </div>
  )
};

Logo.propTypes = {
  logo: propsValidation.logo.isRequired,
  title: PropTypes.string.isRequired,
};

export default Logo;
