import React from "react";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import * as propsValidation from "../prop-types";

const Breadcrumb = ({ breadcrumb }) => {
  const { blueText, linkList } = breadcrumb;

  const isLast = index => linkList.length - 1 === index;

  const isLink = (item, index) =>
    !item.class.includes("disabled-link") && !isLast(index);

  const createSchemaData = () => {
    const links = [...linkList];
    links.shift();
    return links.map((link, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: link.title,
      ...(!isLast(index + 1) ? { item: link.href } : {})
    }));
  };

  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: createSchemaData()
          })
        ]}
      />
      <div className={`breadcrumb ${blueText ? "text-blue-85" : ""}`}>
        <ol>
          {linkList.map((el, index) => (
            <li
              key={index}
              className={`item${index + 1} ${isLast(index) ? "last" : ""}`}
            >
              {isLink(el, index) ? (
                <a
                  href={el.href}
                  title={el.title}
                  target={el.target}
                  className={el.class}
                  dangerouslySetInnerHTML={{ __html: el.title }}
                />
              ) : (
                <span
                  className="field-title"
                  dangerouslySetInnerHTML={{ __html: el.title }}
                />
              )}
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

Breadcrumb.propTypes = {
  breadcrumb: propsValidation.breadcrumb.isRequired
};

export default Breadcrumb;
