export var componentsSchemas = {
  'intro-banner': {
    data: [{
      name: 'image',
      element: '.intro-banner-image img',
      type: 'image'
    }, {
      name: 'mobileImage',
      element: '.intro-banner-image',
      type: 'mobileImage'
    }, {
      name: 'video',
      element: '.intro-banner-video source',
      type: 'video'
    }, {
      name: 'embeddedVideo',
      element: '.intro-banner-video-external iframe',
      type: 'iframe'
    }, {
      name: 'content',
      element: '.intro-banner-text',
      type: 'html'
    }, {
      name: 'link',
      element: '.intro-banner-link',
      type: 'html'
    }, {
      name: 'anchorLink',
      element: '.sticky-downlink a',
      type: 'link'
    }, {
      name: 'textList',
      element: '.intro-banner-list-spot-content',
      type: 'list'
    }]
  },
  'rich-text': {
    data: [{
      name: 'content',
      element: '.component-content',
      type: 'html'
    }]
  },
  'text-image-spot': {
    data: [{
      name: 'image',
      element: '.text-image-spot-image',
      type: 'image'
    }, {
      name: 'imageDescription',
      element: '.text-image-spot-image-description',
      type: 'text'
    }, {
      name: 'content',
      element: '.text-image-spot-text',
      type: 'html'
    }, {
      name: 'link',
      element: '.text-image-spot-image a',
      type: 'link'
    }]
  },
  'news-list-spot': {
    data: [{
      name: 'newsListSpot',
      element: 'ul',
      type: 'newsListSpot'
    }]
  },
  'news-spot-item': {
    data: [{
      name: 'title',
      element: '.field-title',
      type: 'text'
    }, {
      name: 'date',
      element: '.field-date',
      type: 'html'
    }, {
      name: 'content',
      element: '.field-content',
      type: 'html'
    }, {
      name: 'image',
      element: '.field-summaryicon img',
      type: 'image'
    }, {
      name: 'bannerImage',
      element: '.field-newsspotimage img',
      type: 'image'
    }]
  },
  'news-navigation': {
    data: [{
      name: 'linkList',
      element: '.component-content ul',
      type: 'newsNavigation'
    }]
  },
  'hearing-test-invitation': {
    data: [{
      name: 'title',
      element: '.hti-title',
      type: 'text'
    }, {
      name: 'description',
      element: '.hti-description',
      type: 'html'
    }, {
      name: 'confirmationUrl',
      element: '.hti-confirmation-url',
      type: 'text'
    }, {
      name: 'hearingTestUrl',
      element: '.hti-hearing-test-url',
      type: 'text'
    }, {
      name: 'recipientNameLabel',
      element: '.hti-recipient-name-label',
      type: 'text'
    }, {
      name: 'recipientEmailLabel',
      element: '.hti-recipient-email-label',
      type: 'text'
    }, {
      name: 'senderNameLabel',
      element: '.hti-sender-name-label',
      type: 'text'
    }, {
      name: 'senderEmailLabel',
      element: '.hti-sender-email-label',
      type: 'text'
    }, {
      name: 'messageLabel',
      element: '.hti-message-label',
      type: 'text'
    }, {
      name: 'messageDefaultTextLabel',
      element: '.hti-message-default-text-label',
      type: 'text'
    }, {
      name: 'submitButtonLabel',
      element: '.hti-submit-button',
      type: 'text'
    }, {
      name: 'confirmationTitle',
      element: '.hti-confirmation-title',
      type: 'text'
    }, {
      name: 'confirmationDescription',
      element: '.hti-confirmation-description',
      type: 'html'
    }, {
      name: 'submitTitle',
      element: '.hti-submit-title',
      type: 'text'
    }, {
      name: 'submitDescription',
      element: '.hti-submit-description',
      type: 'html'
    }, {
      name: 'errorTitle',
      element: '.hti-submit-error-title',
      type: 'text'
    }, {
      name: 'errorExpired',
      element: '.hti-submit-error-expired',
      type: 'text'
    }, {
      name: 'errorNotExists',
      element: '.hti-submit-error-not-exists',
      type: 'text'
    }, {
      name: 'utmTracking',
      element: '.hti-utm-tracking',
      type: 'text'
    }, {
      name: 'gaCookieId',
      element: '.hti-ga-cookie-id',
      type: 'text'
    }, {
      name: 'trackingCodeOne',
      element: '.hti-tracking-code-one',
      type: 'text'
    }, {
      name: 'trackingCodeTwo',
      element: '.hti-tracking-code-two',
      type: 'text'
    }, {
      name: 'trackingCodeOneName',
      element: '.hti-tracking-code-one-name',
      type: 'text'
    }, {
      name: 'trackingCodeTwoName',
      element: '.hti-tracking-code-two-name',
      type: 'text'
    }, {
      name: 'inviteAnother',
      element: '.hti-invite-another-label',
      type: 'text'
    }]
  },
  'center-locator': {
    data: []
  },
  'image-spot': {
    data: [{
      name: 'imageSpotList',
      element: '.component-content ul',
      type: 'imageSpotList'
    }]
  },
  'color-spot': {
    data: [{
      name: 'items',
      element: '.colors-container',
      type: 'colorSpotContent'
    }]
  },
  header: {
    data: [{
      name: 'logo',
      element: '.component.image',
      type: 'imageComponent'
    }, {
      name: 'navigation',
      element: '.component.navigation .mobile-nav + ul',
      type: 'navigation'
    }, {
      name: 'link',
      element: '.component.link a',
      type: 'link'
    }, {
      name: 'languageSelector',
      element: '.language-selector-spot ul',
      type: 'linkList'
    }, {
      name: 'search',
      element: '.search-field-spot',
      type: 'search'
    }, {
      name: 'breadcrumb',
      element: '.component.breadcrumb',
      type: 'breadcrumb'
    }]
  },
  'blog-categories': {
    data: [{
      name: 'categories',
      element: '.blog-categories .blog-categories-content-wrapper',
      type: 'blogCategories'
    }]
  },
  'ui-elements': {
    data: [{
      name: 'data',
      element: '.ui-elements script',
      type: 'json'
    }]
  },
  'side-navigation': {
    data: [{
      name: 'linkList',
      element: '.component-content ul',
      type: 'linkList'
    }]
  }
};