require('../../../corporate-template/js/feature/cookies.js');
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    buildContainerForCookieButtons();
    moveCookiePolicyLinkContainerToBottom();
    function buildContainerForCookieButtons() {
      var infoSection = $('.cookie-warning .info');
      var cookieBtnsContainer = $('<div class="btns-container"></div>');
      var submitBtn = $('.cookie-warning .submit');
      var editSettingsBtn = $('.cookie-warning .info .like-white-blue-button').parent();
      infoSection.append(cookieBtnsContainer);
      cookieBtnsContainer.append(submitBtn).append(editSettingsBtn);
    }
    function moveCookiePolicyLinkContainerToBottom() {
      var cookiePolicyLinkContainer = $('.cookie-warning a[href*=cookie-policy]:not(.like-white-blue-button)').parent();
      var cookieBtnsContainer = $('.btns-container');
      cookiePolicyLinkContainer.addClass('cookie-policy-link');
      cookiePolicyLinkContainer.insertAfter(cookieBtnsContainer);
    }
  });
})(jQuery, window.DGS);