import React from 'react';
import PropTypes from 'prop-types';
import * as propsValidation from "../../prop-types";
import { addTrackingToProductClick } from "../../../../../../js/feature/gtm-tracking";

class DesktopNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLevel1Index: null,
      activeLevel2Index: null,
      activeLevel3Index: null
    };

    this.navItemsFromCurrentURL = {
      currentLevel1Index: null,
      currentLevel2Index: null,
      currentLevel3Index: null
    };

    this.closeNavOnEscape = this.closeNavOnEscape.bind(this);
  }

  componentDidMount() {
    this.setNavItemsThatArePartOfCurrentURL();
    document.addEventListener('keydown', this.closeNavOnEscape);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeNavOnEscape);
  }

  componentDidUpdate(prevState) {
    if (this.state.activeLevel1Index !== prevState.activeLevel1Index) {
      this.handleWindowScroll();
    }
  }

  setNavItemsThatArePartOfCurrentURL() {
    const links = this.getLinksFromCurrentURL();
    this.props.navigation.map((item1, indexLevelOne) => {
      if (item1.nestedLinks !== null) {
        item1.nestedLinks.map((item2, indexLevelTwo) => {
          if (links.includes(item2.link.pathname)) {
            this.navItemsFromCurrentURL.currentLevel1Index = indexLevelOne;
            this.navItemsFromCurrentURL.currentLevel2Index = indexLevelTwo;
            if (item2.nestedLinks !== null) {
              item2.nestedLinks.map((item3, indexLevelThree) => {
                if (links.includes(item3.link.pathname)) {
                  this.navItemsFromCurrentURL.currentLevel3Index = indexLevelThree;
                }
              });
            }
          }
        })
      }
    });
  }

  getLinksFromCurrentURL() {
    const urlParts = window.location.pathname.split('/').slice(1);
    const links = [];
    urlParts.map((urlPart, index) => {
      let linkUrl = '';
      for (let i = 0; i <= index; i++) {
        linkUrl += '/' + urlParts[i];
      }
      links.push(linkUrl);
    });
    return links;
  }

  closeNavOnEscape(e) {
    if (e.keyCode === 27) {
      this.closeSubNavigation(e);
    }
  }

  handleWindowScroll() {
    if (this.state.activeLevel1Index !== null) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  handleLevelOnClick(e, index, level, isLast) {
    e.preventDefault();
    if (this.state[`activeLevel${level}Index`] !== index) {
      if (level === 1) {
        const currentLevel2Index = this.navItemsFromCurrentURL.currentLevel1Index === index ? this.navItemsFromCurrentURL.currentLevel2Index : null;
        this.setState({ activeLevel2Index: currentLevel2Index });
      }
      if (level === 1 || level === 2) {
        this.setState({ activeLevel3Index: null });
      }
      this.setState({ [`activeLevel${level}Index`]: index });
    }
    if (level === 1 && this.state.activeLevel1Index === index) {
      this.closeSubNavigation(e);
    }
    if (isLast) {
      addTrackingToProductClick(e, e.target.href, "_self", "navigation_menu");
    }
  }

  closeSubNavigation(e) {
    e.preventDefault();
    this.setState({ activeLevel1Index: null, activeLevel2Index: null });
  }

  render() {
    const { navigation, dictionary } = this.props;
    const { activeLevel1Index, activeLevel2Index, activeLevel3Index } = this.state;
    const { currentLevel1Index, currentLevel2Index, currentLevel3Index } = this.navItemsFromCurrentURL;
    return (
     <div className="navigation">
       <ul className="level1" role="menubar">
         {navigation.map((levelOne, levelOneIndex) => {
           return (
           <li
             key={levelOneIndex}
             className={`level1__item ${activeLevel1Index === levelOneIndex ? 'active' : ''}`}
             role="none"
           >
             <a
               href={levelOne.link.href}
               role="menuitem"
               aria-expanded={activeLevel1Index === levelOneIndex}
               aria-haspopup={levelOne.nestedLinks !== null}
               onClick={(e) => this.handleLevelOnClick(e, levelOneIndex, 1)}
               dangerouslySetInnerHTML={{ __html: levelOne.link.content }}
             />
             {levelOne.nestedLinks !== null &&
               <div className="sub-navigation">
                 <div className="sub-navigation__container">
                   <h3 className="sub-navigation__header" dangerouslySetInnerHTML={{ __html: levelOne.link.content }} />
                   <ul
                     className={`level2 ${activeLevel1Index === levelOneIndex ? 'active' : ''}`}
                     role="menu"
                     aria-label={levelOne.link.content}
                   >
                     {levelOne.nestedLinks.map((levelTwo, levelTwoIndex) => {
                       const isCurrentOfLevel2 = currentLevel1Index === levelOneIndex && currentLevel2Index === levelTwoIndex;
                       const isActiveOfLevel2 = activeLevel1Index === levelOneIndex && activeLevel2Index === levelTwoIndex;
                       const isFadedOfLevel2 = activeLevel1Index === levelOneIndex && activeLevel2Index !== null && activeLevel2Index !== levelTwoIndex;
                       const hasChildren = levelTwo.nestedLinks !== null;
                       const hasGroupedChildren = hasChildren && levelTwo.groupedNestedLinks !== null;
                       return (
                         <li
                           key={levelTwoIndex}
                           className={`level2__item ${isCurrentOfLevel2 ? 'current' : ''} ${isActiveOfLevel2 ? 'active' : ''} ${isFadedOfLevel2 ? 'faded' : ''} ${!hasChildren ? 'last': ''}`}
                           role="none"
                         >
                           {
                             !hasChildren &&
                             <a href={levelTwo.link.href} role="menuitem" onClick={(e) => this.handleLevelOnClick(e, levelTwoIndex, 2, true)} dangerouslySetInnerHTML={{ __html: levelTwo.link.content }} />
                           }
                           {
                             hasChildren && !hasGroupedChildren &&
                             <>
                               <a
                                 href={levelTwo.link.href}
                                 role="menuitem"
                                 aria-expanded={isActiveOfLevel2}
                                 aria-haspopup={levelTwo.nestedLinks !== null}
                                 onClick={(e) => this.handleLevelOnClick(e, levelTwoIndex, 2)}
                                 dangerouslySetInnerHTML={{ __html: levelTwo.link.content }}
                               />
                               <ul
                                 className={`level3 ${isActiveOfLevel2 ? 'active' : ''} ${isCurrentOfLevel2 ? 'current' : ''}`}
                                 role="menu"
                                 aria-label={levelTwo.link.content}
                               >
                                 {levelTwo.nestedLinks.map((levelThree, levelThreeIndex) => {
                                   const isCurrentOfLevel3 = isCurrentOfLevel2 && currentLevel3Index === levelThreeIndex;
                                   const isActiveOfLevel3 = isActiveOfLevel2 && activeLevel3Index === levelThreeIndex;
                                   const isFadedOfLevel3 = (isCurrentOfLevel2 && currentLevel3Index !== levelThreeIndex) || (activeLevel3Index !== null && activeLevel3Index !== levelThreeIndex && isActiveOfLevel2);
                                   return (
                                     <li
                                       key={levelThreeIndex}
                                       className={`level3__item last ${levelThree.link.class} ${isCurrentOfLevel3 ? 'current' : ''} ${isActiveOfLevel3 ? 'active' : ''} ${isFadedOfLevel3 ? 'faded' : ''}`}
                                       role="none"
                                     >
                                       <a
                                         href={levelThree.link.href}
                                         role="menuitem"
                                         onClick={(e) => this.handleLevelOnClick(e, levelThreeIndex, 3, true)}
                                         dangerouslySetInnerHTML={{ __html: levelThree.link.content }}
                                       />
                                     </li>
                                   )
                                 })}
                               </ul>
                             </>
                           }
                           {
                             hasGroupedChildren &&
                             <>
                               <a
                                 href={levelTwo.link.href}
                                 role="menuitem"
                                 aria-expanded={isActiveOfLevel2}
                                 aria-haspopup={levelTwo.nestedLinks !== null}
                                 onClick={(e) => this.handleLevelOnClick(e, levelTwoIndex, 2)}
                                 dangerouslySetInnerHTML={{ __html: levelTwo.link.content }}
                               />
                               <div className={`level3__group-container ${isActiveOfLevel2 ? 'active' : ''} ${isCurrentOfLevel2 ? 'current' : ''}`}>
                                 {
                                   levelTwo.groupedNestedLinks.map((groupOfLinks, groupIndex) => {
                                     const groupHasTitle = dictionary[`LevelThreeTitle${groupIndex+1}`].length > 0;
                                     return (
                                       <ul key={groupIndex} role="menu" aria-label={groupHasTitle ? dictionary[`LevelThreeTitle${groupIndex+1}`] : ''}>
                                         {
                                           groupHasTitle &&
                                           <li role="none" className="level3__title">
                                             <h5 dangerouslySetInnerHTML={{ __html: dictionary[`LevelThreeTitle${groupIndex+1}`] }} />
                                           </li>
                                         }
                                         {groupOfLinks.map((item) => {
                                           const itemIndex = levelTwo.nestedLinks.findIndex(el => el.link.href === item.link.href);
                                           const isCurrentOfLevel3 = isCurrentOfLevel2 && currentLevel3Index === itemIndex;
                                           const isActiveOfLevel3 = isActiveOfLevel2 && activeLevel3Index === itemIndex;
                                           const isFadedOfLevel3 = (isCurrentOfLevel2 && currentLevel3Index !== itemIndex) || (activeLevel3Index !== null && activeLevel3Index !== itemIndex && isActiveOfLevel2);
                                           return (
                                             <li
                                               key={itemIndex}
                                               role="none"
                                               className={`level3__item last ${isCurrentOfLevel3 ? 'current' : ''} ${isActiveOfLevel3 ? 'active' : ''} ${isFadedOfLevel3 ? 'faded' : ''}`}>
                                               <a
                                                 href={item.link.href}
                                                 role="menuitem"
                                                 onClick={(e) => this.handleLevelOnClick(e, itemIndex, 3, true)}
                                                 dangerouslySetInnerHTML={{ __html: item.link.content }} />
                                             </li>
                                           )
                                         })}
                                       </ul>
                                     )
                                   })
                                 }
                               </div>
                             </>
                           }
                         </li>
                       )
                     })}
                   </ul>
                   <button
                     className="btn-close"
                     aria-label={dictionary.CloseMenuLabel}
                     onClick={(e) => this.closeSubNavigation(e)}
                   >{dictionary.CloseMenuLabel}</button>
                 </div>
               </div>
             }
           </li>
         )})}
       </ul>
     </div>
    )
  }
}

DesktopNavigation.propTypes = {
  navigation: propsValidation.navigation.isRequired
};

export default DesktopNavigation;
