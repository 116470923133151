var cssLoader = function cssLoader() {
  var cssChunks = {
    ".accordion-spot.component": "accordion-spot",
    ".blog-categories.component": "blog-categories",
    ".center-locator.component": "center-locator",
    ".color-spot": "color-selector-spot",
    ".compatibility-tool": "compatibility-tool",
    ".dictionary-list, .dictionary-post": "dictionary",
    ".page-dir-rtl": "page-dir-rtl",
    ".embed-form-container": "embed-form",
    ".flex-spot.banner": "flex-spot-banner",
    ".hearing-loss-simulator-spot": "hearing-loss-simulator-spot",
    ".hearing-test-invitation": "hearing-test-invitation",
    ".hearing-test-spot:not(.quiz), .hearing-test-intro, .hearing-test-result": "hearing-test-spot",
    ".image-spot.component.accordion-img-as-tab": "image-spot-accordion",
    ".image-spot.component.as-carousel": "image-spot-as-carousel",
    ".image-spot.component.buttons-container": "image-spot-buttons-container",
    ".image-spot.component.side-accordion": "image-spot-side-accordion",
    ".locations-spot": "locations-spot",
    ".news-list-spot": "news-list-spot",
    ".news-spot-item": "news-spot-item",
    ".news-navigation": "news-navigation",
    ".quiz.hearing-test-spot": "quiz",
    ".resource-center": "resource-center",
    ".scfForm": "standard-form",
    ".search-field-spot": "search-field-spot",
    ".search-result-spot": "search-result-spot",
    ".side-navigation": "side-navigation",
    ".two-column-spot.component": "two-column-spot",
    ".ui-elements.data-hierarchy-spot": "ui-elements",
    ".two-sections-layout.flex-spot": "two-sections-layout",
    ".video-spot.component": "video-spot"
  };
  Object.keys(cssChunks).map(function (key) {
    var element = document.querySelector(key);
    var link = document.querySelector("link[data-component^=\"".concat(cssChunks[key], "\"]"));
    if (element && link) {
      link.setAttribute("href", link.getAttribute("data-href"));
    }
  });
};
export default cssLoader;