require('../../../corporate-template/js/feature/intro-banner.js');
DGS.Resizer.getInstance().register(function () {
  document.querySelectorAll('.component.intro-banner.embed-form-container.img-as-bg').forEach(function (component) {
    var content = component.querySelector('.intro-banner-content');
    var image = component.querySelector('.intro-banner-image img');
    var desktop = window.matchMedia("(min-width: 1200px)");
    if (desktop.matches) {
      content.style.backgroundImage = "url(".concat(image.src, ")");
    } else {
      content.style.backgroundImage = "none";
    }
  });
  adjustMapTextForLocationSelector();
  function adjustMapTextForLocationSelector() {
    var textContainer = $('.component.intro-banner.adjust-text-to-map');
    textContainer.removeClass('content-overflow');
  }
});
(function ($, DGS) {
  DGS.OnContentLoaded.getInstance().register(function () {
    function showExternalVideo(iframe) {
      setTimeout(function () {
        iframe.parentElement.parentElement.classList.add('ex-video-ready');
      }, 250);
    }
    var iframes = document.querySelectorAll('.intro-banner .intro-banner-video-external');
    iframes.forEach(showExternalVideo);
  });
})(jQuery, window.DGS);