require("../../../corporate-template/js/project/main.js");
import { addTrackingToProductClick } from "../feature/gtm-tracking";
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var handleTrackingOnLinkClickToProductPage = function handleTrackingOnLinkClickToProductPage() {
      var linksToProduckPages = document.querySelectorAll("a[href*='/hearing-aids/hearing-aids/']");
      linksToProduckPages.forEach(function (link) {
        var target = link.target.length > 0 ? link.target : "_self";
        link.addEventListener("click", function (e) {
          return addTrackingToProductClick(e, link.href, target, "button_click");
        });
      });
    };
    handleTrackingOnLinkClickToProductPage();
    function makePhoneNumberClickable() {
      $(".clickable-phone-number").each(function () {
        var self = $(this);
        var phoneNumber = self[0].innerText;
        self.wrap("<a href='tel:".concat(phoneNumber, "'></a>"));
      });
    }
    makePhoneNumberClickable();
    function makePhoneNumberClickableForMobile() {
      $(".clickable-phone-number-mobile-only").each(function () {
        var self = $(this);
        var phoneNumber = self[0].innerText;
        var MOBILE_BP = window.matchMedia("(max-width: 767px)");
        if (MOBILE_BP.matches) {
          self.wrap("<a href='tel:".concat(phoneNumber, "'></a>"));
        } else {
          self.closest("[href=\"tel:".concat(phoneNumber, "\"]")).replaceWith(self);
        }
      });
    }
    function manageFocusOnTabKeyUse(e) {
      if (e.keyCode === 9) {
        $("body").addClass("user-is-tabbing");
        $(window).off("keydown", manageFocusOnTabKeyUse);
        $(window).on("mousedown", manageFocusOnMouseUse);
      }
    }
    function manageFocusOnMouseUse() {
      $("body").removeClass("user-is-tabbing");
      $(window).off("mousedown", manageFocusOnMouseUse);
      $(window).on("keydown", manageFocusOnTabKeyUse);
    }
    function manageScrollOnTabKeyUse(e) {
      if (e.keyCode === 9) {
        var hasScrolled = $(window).scrollTop() > 0;
        var windowHeight = window.innerHeight;
        var targetTop = e.target.getBoundingClientRect().top;
        var targetBottom = e.target.getBoundingClientRect().bottom;
        var targetIsAPartOfHeader = $(".header .section-one").has(e.target).length > 0;
        var targetIsAPartOfBreadcrumbs = $(".header .section-two").has(e.target).length > 0;
        if (hasScrolled && targetTop < 50 && !targetIsAPartOfHeader) {
          if (targetIsAPartOfBreadcrumbs) {
            window.scrollTo(0, 0);
          } else {
            window.scrollBy(0, -100);
          }
        }
        if (targetBottom > windowHeight - 10) {
          window.scrollBy(0, 100);
        }
      }
    }
    function manageFocus() {
      $(window).on("keydown", manageFocusOnTabKeyUse);
      $(window).on("keyup", manageScrollOnTabKeyUse);
    }
    manageFocus();
    function correctScrollForAnchor() {
      $('[href^="#"]').on("click", function (e) {
        e.preventDefault();
        var target = $(this).attr("href");
        var correction = $("#header").outerHeight(true);
        $("body,html").animate({
          scrollTop: $(target).offset().top - correction
        }, 500);
      });
    }
    correctScrollForAnchor();
    DGS.Resizer.getInstance().register(function () {
      makePhoneNumberClickableForMobile();
    });
    function controlAccessibilityAttributesForConditionalButton(button) {
      var input = button.parentNode.querySelector("input");
      var descriptionID = button.getAttribute("aria-describedby");
      input.addEventListener("change", function () {
        if (input.checked) {
          button.setAttribute("aria-disabled", "false");
          button.removeAttribute("aria-describedby");
        } else {
          button.setAttribute("aria-disabled", "true");
          button.setAttribute("aria-describedby", descriptionID);
        }
      });
    }
    function disableButtonIfConditionsNotAccepted(button) {
      var input = button.parentNode.querySelector("input");
      button.addEventListener("click", function (e) {
        if (!input.checked) {
          e.preventDefault();
        }
      });
    }
    function manageConditionalButtons() {
      var buttons = document.querySelectorAll(".conditional-btn");
      buttons.forEach(function (button) {
        controlAccessibilityAttributesForConditionalButton(button);
        disableButtonIfConditionsNotAccepted(button);
      });
    }
    manageConditionalButtons();
  });
})(jQuery, window.DGS);